<template>
    <section>
        <TitleBar title="Numune Detayları" :modi="modi" />

        <b-tabs>
            <b-tab-item class="columns is-multiline" label="Genel">
                <b-field class="column is-6 box" label="Tanım" grouped group-multiline>
                    <b-field class="column is-12" label="ID">
                        <b-input v-model="sample.id" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Numune Açıklaması">
                        <b-input v-model="sample.name" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Müşteri Unvan">
                        <CustomerAutofill v-model="sample.customer" :disabled="modi == 'show'" />
                    </b-field>
                    <b-field class="column is-12" label="Müşteri Temsilcisi">
                        <b-input v-model="sample.customer.cusrep.name" disabled></b-input>
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Durum" grouped group-multiline>
                    <b-radio-button class="column is-6" type="is-danger is-light" size="is-small" native-value="Çalışma Bekliyor" v-model="sample.status" :disabled="modi == 'show'" expanded>
                        Çalışma Bekliyor
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-info is-light" size="is-small" native-value="Reçete Çalışmasında" v-model="sample.status" :disabled="modi == 'show'" expanded>
                        Reçete Çalışmasında
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-link is-light" size="is-small" native-value="Malzeme Kaydı Bekliyor" v-model="sample.status" :disabled="modi == 'show'" expanded>
                        Malzeme Kaydı Bekliyor
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-warning is-light" size="is-small" native-value="Fiyat Bekliyor" v-model="sample.status" :disabled="modi == 'show'" expanded>
                        Fiyat Bekliyor
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-primary is-light" size="is-small" native-value="Sevk Aşamasında" v-model="sample.status" :disabled="modi == 'show'" expanded>
                        Sevk Aşamasında
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-success is-light" size="is-small" native-value="Sevk Edildi" v-model="sample.status" :disabled="modi == 'show'" expanded>
                        Sevk Edildi
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-warning is-light" size="is-small" native-value="Askıda" v-model="sample.status" :disabled="modi == 'show'" expanded>
                        Askıda
                    </b-radio-button>
                    <b-radio-button class="column is-6" type="is-light" size="is-small" native-value="İptal" v-model="sample.status" :disabled="modi == 'show'" expanded>
                        İptal
                    </b-radio-button>
                </b-field>

                <b-field class="column is-6 box" label="Bilgiler" grouped group-multiline>
                    <b-field class="column is-12" label="Giriş Tarihi">
                        <b-datetimepicker size="is-small" icon="calendar-today" placeholder="Seciniz..." v-model="sample.indate" :timepicker="{ hourFormat: '24' }" :disabled="modi == 'show'" horizontal-time-picker rounded> </b-datetimepicker>
                    </b-field>
                    <b-field class="column is-12" label="Çıkış Tarihi">
                        <b-datetimepicker size="is-small" icon="calendar-today" placeholder="Seciniz..." v-model="sample.outdate" :timepicker="{ hourFormat: '24' }" :disabled="modi == 'show'" horizontal-time-picker rounded> </b-datetimepicker>
                    </b-field>
                    <b-field class="column is-12" label="Anma Rengi">
                        <b-input v-model="sample.color" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Sektörel Özellik">
                        <b-input v-model="sample.sector" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Talep Miktarı (KG)">
                        <b-numberinput type="is-link is-light" placeholder="0.00" controls-alignment="right" controls-position="compact" v-model="sample.quantity" :min-step="0.001" :disabled="modi == 'show'" expanded></b-numberinput>
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Notlar" grouped group-multiline>
                    <b-input class="column is-12" type="textarea" v-model="sample.note" :rows="8" :disabled="modi == 'show'"></b-input>
                </b-field>
            </b-tab-item>

            <b-tab-item class="columns" label="Reçete">
                <b-field class="column is-12 box" label="Numune Reçetesi Tablosu" grouped group-multiline>
                    <b-field class="column is-12" position="is-right" grouped group-multiline>
                        <b-button class="column" type="is-success is-light" icon-left="plus" label="Ekle" @click="bom_add" :disabled="modi == 'show'" />
                        <b-button class="column ml-1" type="is-warning is-light" icon-left="import" label="Temizle" disabled />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Yazdır" />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" />
                    </b-field>

                    <b-table class="column is-12" :key="$store.state.ui.flush" :data="sample.boms" :mobile-cards="false" striped narrowed>
                        <template slot="empty">
                            <div class="is-12 has-text-centered">
                                Numune reçetesi kaydı bulunamadı.
                            </div>
                        </template>

                        <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-5) }}</b-table-column>
                        <b-table-column field="item.code" label="Malzeme Kodu" v-slot="props" sortable>
                            <MaterialAutofill v-model="props.row.item" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="item.name" label="Malzeme Açıklaması" v-slot="props" sortable>{{ props.row.item.name }}</b-table-column>
                        <b-table-column field="lotnum" label="Parti No" v-slot="props" sortable>
                            <LotAutofill v-model="props.row.lotnum" @value="(v) => (props.row.lotnum = v)" :id_material="props.row.item.id" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" sortable>
                            <b-numberinput type="is-link is-light" placeholder="0.00" controls-alignment="right" controls-position="compact" v-model="props.row.quantity" :min-step="0.001" :disabled="modi == 'show'" expanded></b-numberinput>
                        </b-table-column>
                        <b-table-column field="action" label="Aksiyon" v-slot="props"> <b-button type="is-danger is-light" icon-left="delete" @click="bom_delete(props.row.id, props.index)" :disabled="modi == 'show'"/></b-table-column>
                    </b-table>
                </b-field>
            </b-tab-item>

            <b-tab-item class="columns is-multiline" label="Kalite">
                <b-field class="column is-3 box" label="Referans L*a*b* [SCI]" grouped group-multiline>
                    <b-field class="column is-12" label="L*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-alignment="right"
                            controls-position="compact"
                            v-model="sample.refcieli"
                            :min-step="0.001"
                            :min="-100"
                            :max="100"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="a*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="sample.refcieai"
                            :min-step="0.001"
                            :min="-256"
                            :max="256"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="b*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="sample.refciebi"
                            :min-step="0.001"
                            :min="-256"
                            :max="256"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                </b-field>

                <b-field class="column is-3 box" label="Numune L*a*b* [SCI]" grouped group-multiline>
                    <b-field class="column is-12" label="L*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-alignment="right"
                            controls-position="compact"
                            v-model="sample.cieli"
                            :min-step="0.001"
                            :min="-100"
                            :max="100"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="a*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="sample.cieai"
                            :min-step="0.001"
                            :min="-256"
                            :max="256"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="b*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="sample.ciebi"
                            :min-step="0.001"
                            :min="-256"
                            :max="256"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                </b-field>

                <b-field class="column is-3 box" label="Referans L*a*b* [SCE]" grouped group-multiline>
                    <b-field class="column is-12" label="L*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-alignment="right"
                            controls-position="compact"
                            v-model="sample.refciele"
                            :min-step="0.001"
                            :min="-100"
                            :max="100"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="a*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="sample.refcieae"
                            :min-step="0.001"
                            :min="-256"
                            :max="256"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="b*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="sample.refciebe"
                            :min-step="0.001"
                            :min="-256"
                            :max="256"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                </b-field>

                <b-field class="column is-3 box" label="Numune L*a*b* [SCE]" grouped group-multiline>
                    <b-field class="column is-12" label="L*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-alignment="right"
                            controls-position="compact"
                            v-model="sample.ciele"
                            :min-step="0.001"
                            :min="-100"
                            :max="100"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="a*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="sample.cieae"
                            :min-step="0.001"
                            :min="-256"
                            :max="256"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                    <b-field class="column is-12" label="b*">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="00.000"
                            controls-position="compact"
                            controls-alignment="right"
                            v-model="sample.ciebe"
                            :min-step="0.001"
                            :min="-256"
                            :max="256"
                            :disabled="modi == 'show'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Renk Farkı" grouped group-multiline>
                    <b-field class="column is-12" label="ΔL* [SCI]">
                        <b-input placeholder="00.000" v-model="dli" :controls="false" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Δa* [SCI]">
                        <b-input placeholder="00.000" v-model="dai" :controls="false" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Δb* [SCI]">
                        <b-input placeholder="00.000" v-model="dbi" :controls="false" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="ΔE [SCI]">
                        <b-input placeholder="00.000" v-model="dei" :controls="false" disabled></b-input>
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Renk Farkı" grouped group-multiline>
                    <b-field class="column is-12" label="ΔL* [SCE]">
                        <b-input placeholder="00.000" v-model="dle" :controls="false" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Δa* [SCE]">
                        <b-input placeholder="00.000" v-model="dae" :controls="false" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Δb* [SCE]">
                        <b-input placeholder="00.000" v-model="dbe" :controls="false" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="ΔE [SCE]">
                        <b-input placeholder="00.000" v-model="dee" :controls="false" disabled></b-input>
                    </b-field>
                </b-field>
            </b-tab-item>

            <b-tab-item class="columns is-multiline" label="Adres">
                <b-field class="column is-6 box" label="Fatura" grouped group-multiline>
                    <b-field class="column is-12" label="Adres">
                        <b-input class="column is-12" type="textarea" v-model="sample.invoaddr" :rows="5" :disabled="modi == 'show'"></b-input>
                    </b-field>
                </b-field>
                <b-field class="column is-6 box" label="Sevk" grouped group-multiline>
                    <b-field class="column is-12" label="Adres">
                        <b-input class="column is-12" type="textarea" v-model="sample.shipaddr" :rows="5" :disabled="modi == 'show'"></b-input>
                    </b-field>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Kayıt Gecmisi" :disabled="!sample.id">
                <MetaDetails v-if="$route.query.id" />
            </b-tab-item>
        </b-tabs>

        <b-field position="is-right">
            <b-button class="ml-1" type="is-success is-light" icon-left="check" label="Kaydet" :disabled="modi == 'show'" @click="sample_save" />
            <b-button class="ml-1" type="is-danger is-light" icon-left="close" label="Iptal" @click="sample_cancel" />
        </b-field>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import LotAutofill from "@/components/LotAutofill.vue";
import MaterialAutofill from "@/components/MaterialAutofill.vue";
import CustomerAutofill from "@/components/CustomerAutofill.vue";
import MetaDetails from "@/components/MetaDetails.vue";

export default {
    name: "SampleDetails",
    components: {
        TitleBar,
        MaterialAutofill,
        CustomerAutofill,
        LotAutofill,
        MetaDetails,
    },
    computed: {
        dli() {
            return Math.sqrt(Math.pow(this.sample.refcieli - this.sample.cieli, 2));
        },
        dai() {
            return Math.sqrt(Math.pow(this.sample.refcieai - this.sample.cieai, 2));
        },
        dbi() {
            return Math.sqrt(Math.pow(this.sample.refciebi - this.sample.ciebi, 2));
        },
        dei() {
            return Math.sqrt(Math.pow(this.dli, 2) + Math.pow(this.dai, 2) + Math.pow(this.dbi, 2));
        },
        dle() {
            return Math.sqrt(Math.pow(this.sample.refciele - this.sample.ciele, 2));
        },
        dae() {
            return Math.sqrt(Math.pow(this.sample.refcieae - this.sample.cieae, 2));
        },
        dbe() {
            return Math.sqrt(Math.pow(this.sample.refciebe - this.sample.ciebe, 2));
        },
        dee() {
            return Math.sqrt(Math.pow(this.dle, 2) + Math.pow(this.dae, 2) + Math.pow(this.dbe, 2));
        },
    },
    data: () => ({
        modi: "",
        sample: {
            id: "",
            customer: {
                cusrep: {},
            },
            boms: [],
        },
    }),
    created() {
        this.sample.id = this.$route.query.id || "";
        this.modi = this.$route.query.modi || "add";
        this.sample_read();
    },
    methods: {
        sample_read() {
            if (this.modi == "add") return;
            this.$apollo.queries.readSample.start();
        },
        sample_save() {
            var err = "";
            var payload = this.sample;

            if (!payload.name) err = "Numune açıklaması boş olamaz.";
            if (!payload.status) err = "Durum boş olamaz.";
            if (!payload.customer) err = "Müşteri/Tedarikçi boş olamaz.";
            if (!payload.quantity) err = "Talep miktarı boş olamaz.";
            if (!payload.color) err = "Anma rengi boş olamaz.";
            if (!payload.sector) err = "Sektörel özellik boş olamaz.";
            if (!payload.indate) err = "Giriş tarihi boş olamaz.";

            if (err)
                return this.$buefy.snackbar.open({
                    type: "is-warning",
                    message: err,
                    position: "is-bottom-right",
                });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($sample: String) {
                            saveSample(sample: $sample)
                        }
                    `,
                    variables: {
                        sample: JSON.stringify(payload),
                    },
                })
                .then(() => {
                    this.$buefy.snackbar.open({
                        type: "is-success",
                        message: "Başarılı",
                        position: "is-bottom-right",
                    });
                    return this.$router.push({ path: "/lab/numune/liste" });
                })
                .catch(() => {
                    return this.$buefy.snackbar.open({
                        type: "is-danger",
                        message: "Bir hata oluştu.",
                        position: "is-bottom-right",
                    });
                });
        },
        sample_cancel() {
            this.$router.push({ path: "/lab/numune/liste" });
        },
        bom_add() {
            this.sample.boms.push({ id: "", item: {}, quantity: 0.0 });
        },
        bom_delete(id, index) {
            if (!id) this.sample.boms.splice(index, 1);
            else;
        },
    },
    apollo: {
        readSample: {
            query: gql`
                query readSamples($config: String) {
                    readSamples(config: $config) {
                        id
                        name
                        customer {
                            id
                            name
                            cusrep {
                                id
                                name
                            }
                        }
                        status
                        quantity
                        color
                        sector
                        indate
                        outdate
                        note
                        refcieli
                        refcieai
                        refciebi
                        cieli
                        cieai
                        ciebi
                        refciele
                        refcieae
                        refciebe
                        ciele
                        cieae
                        ciebe
                        boms {
                            id
                            item {
                                id
                                code
                                name
                            }
                            lotnum
                            quantity
                        }
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 1,
                        sorts: [],
                        filters: { id: this.sample.id },
                    }),
                };
            },
            update(data) {
                this.$apollo.queries.readSample.stop();
                this.sample = data.readSamples[0];
                this.sample.indate = new Date(this.sample.indate);
                this.sample.outdate = new Date(this.sample.outdate);

                if (this.modi == "ref") {
                    delete this.sample.id;
                    for (var bom of this.sample.boms) delete bom.id;
                }
            },
        },
    },
};
</script>
